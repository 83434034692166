var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.appConfig.home.watermark && _vm.appConfig.home.watermark.length > 0
        ? _c("div", {
            staticClass: "font-weight-black watermark text-center",
            staticStyle: { "z-index": "1" },
            domProps: { innerHTML: _vm._s(_vm.appConfig.home.watermark) },
          })
        : _vm._e(),
      _c(
        "v-carousel",
        {
          class: { "carousel-dark": _vm.$vuetify.theme.dark },
          staticStyle: { "z-index": "0" },
          attrs: {
            height:
              "calc(100vh - " +
              (_vm.$vuetify.breakpoint.mobile ? 86 : 94) +
              "px)",
            "show-arrows": false,
            "hide-delimiter-background": "",
            "hide-delimiters": "",
            interval: _vm.appConfig.home.carousel.interval,
            cycle: "",
            continuous: "",
          },
          model: {
            value: _vm.carouselModel,
            callback: function ($$v) {
              _vm.carouselModel = $$v
            },
            expression: "carouselModel",
          },
        },
        _vm._l(_vm.appConfig.home.carousel.count, function (index) {
          return _c(
            "v-carousel-item",
            { key: index },
            [
              _c("v-img", {
                attrs: {
                  width: "100vw",
                  "min-width": "100vw",
                  "min-height": "100vh",
                  position: "center center",
                  "aspect-ratio": 16 / 9,
                  src: require("@/assets/carousel/bg-" + index + ".jpg?lazy"),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "placeholder",
                      fn: function () {
                        return [
                          _c(
                            "v-row",
                            {
                              staticClass: "fill-height ma-0",
                              attrs: { align: "center", justify: "center" },
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  indeterminate: "",
                                  color: "grey lighten-5",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "asyent-container",
        [
          _c(
            "v-row",
            {
              staticClass: "fill-height pa-0 ma-0",
              attrs: { "align-content": "end", align: "end", justify: "end" },
            },
            _vm._l(_vm.homeLibrary, function (item, i) {
              return _c(
                "v-col",
                { key: i, attrs: { cols: "12", md: "6", lg: "4", xl: "3" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-card",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "pa-4 fill-height",
                                        attrs: { elevation: "8", outlined: "" },
                                      },
                                      "v-card",
                                      item.target,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item",
                                      { attrs: { "two-line": "" } },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-subtitle", {
                                              staticClass:
                                                "font-weight-light caption text-truncate",
                                              staticStyle: {
                                                "margin-left": "1px",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.ae$prefixLng(
                                                    item["moduleName"] +
                                                      "._library",
                                                    item["parentName"]
                                                  )
                                                ),
                                              },
                                            }),
                                            _c("v-list-item-title", {
                                              staticClass:
                                                "title font-weight-medium text-truncate",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.ae$prefixLng(
                                                    item["moduleName"] +
                                                      "._library",
                                                    item["name"]
                                                  )
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-avatar",
                                          {
                                            attrs: {
                                              horizontal: "",
                                              right: "",
                                              size: _vm.appConfig.home
                                                .avatarSize[0],
                                              color: _vm.getMeta(item).color,
                                            },
                                          },
                                          [
                                            _c("v-icon", {
                                              attrs: {
                                                dark: !_vm.getMeta(item).light,
                                                light: _vm.getMeta(item).light,
                                                size: _vm.appConfig.home
                                                  .avatarSize[1],
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item["icon"]
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      {
                                        staticClass: "text-truncate pt-0 mt-0",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-truncate" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.ae$prefixLng(
                                                  item["moduleName"] +
                                                    "._library._description",
                                                  item["description"]
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.ae$prefixLng(
                              item["moduleName"] + "._library._description",
                              item["description"]
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }